import { TimezoneId, CustomTimezoneId } from '@chart/charting_library';
import { CustomChartIndicators } from '@c/doubled-chart/doubled-chart.model';

export enum SymbolFlags {
  None = 'None',
  Never = 'Never',
  Not = 'Not',
  MayBe = 'May be',
  YesToTrade = 'Yes to trade',
}

export interface IWatchlistTableData {
  id: number;
  symbol: string;
  company_name: string;
  right_trade_position: string;
  left_trade_position: string;
  security_id: number;
  country_code: string;
}

export interface ISymbolData {
  security_id: number;
  symbol: string;
  last_traded_price: number;
  strike_price: number;
  bid_price: number;
  close_40_min: number;
  ask_price: number;
  expiration: any;
  drop_percentage: number;
  dte: number;
  annularized: number;
  company: string;
  option_premium: number;
  exchange: string;
  atr_type: 'conservative' | 'aggressive';
  created_on: string;
  delta: number;
  exchange_code: string;
  flag: SymbolFlags;
  id: number;
  min: number;
  min_premium: number;
  option_type: string;
  industry?: string;
  sector?: string;
  updated_on: string;
  long_descriptions: string | null;

  pe_ratio: number;
  market_cap: number;
  dividends_count: number;
}

export interface ITradeStrategyReportMetadata {
  entry_price: number;
  sma_200: number;
  dividends?: Array<{ date: string, value: number }>;
}

// warning: type is with calculated fields, that are not presented in api response
export interface ITradeStrategyReport {
  bar_entry_date: string;
  bar_exit_date: string;
  created_date: string;
  date: string;
  display_duration: number;
  display_duration_progress: string;
  display_entry_date: string;
  display_entry_price: string;
  display_exit_date: string;
  display_exit_price: string;
  display_dividends: string;
  display_position: string;
  display_position_class: string;
  display_shares_count: string;
  display_signal_price: string;
  display_stop_loss: string;
  display_target_profit: string;
  display_total_profit: string;
  display_total_profit_class: string;
  display_total_profit_progress: number;
  duration_days: number;
  entry_comment: string;
  entry_date: string;
  entry_price: number;
  exit_comment: string;
  exit_date: string;
  exit_price: number;
  dividends: number | null;
  id: number;
  position: string;
  profit: number;
  profit_loss_multiplier: number;
  sma_200: number;
  shares_count: number;
  signal_end_id: number;
  signal_profit_loss_multiplier: number;
  signal_start_id: number;
  signal_stop_loss_amount: number;
  signal_target_profit_amount: number;
  stop_loss: number;
  stop_loss_amount: number;
  strategy_id: number;
  symbol: string;
  target_profit: number;
  target_profit_amount: number;
  total_profit: number;
  metadata: ITradeStrategyReportMetadata | null;
}

export interface FilteredTradeStrategyReportModel extends ITradeStrategyReport {
  is_filter_match: boolean;
}

export interface IOCOOrderData {
  accountId: string;
  duration: string;
  symbol: string;
  quantity: string;
  side: string;
  price: string;
  stop: string;
}

export interface IChartIndicator {
  name: string;
  visible: boolean;
  inputs: Array<{ id: string | number, value: unknown }>;
}

export interface IChartSaveData {
  indicators: IChartIndicator[];
  chartType: number;
  timeZone: TimezoneId | CustomTimezoneId;
  priceScaleMode: number;
}

export type SortDirection = 'asc' | 'desc';

export interface IMarketData {
  symbol: string;
  close: number;
  time: number;
  isWeekend: boolean;
  isHoliday: boolean;
  isPreMarket?: boolean;
  isPostMarket?: boolean;
}

