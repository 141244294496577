<app-search-bar
  [isPowerX]="false"
  #appSearchBar
>
  <app-trading-log-symbol-summary-panel
    [state]="tradingLogSymbolSummaryPanelWheelState | async"
    [symbolName]="appSearchBar.selectedSymbol?.symbol"
    (selected)="summaryPanelValueSelected($event)"
    (statechanged)="summaryPanelStateUpdates($event)"
    *appHideForUnavailableFeature="features.TradingLog"
  ></app-trading-log-symbol-summary-panel>

  <app-wheel-chart-legend
    *appHideForUnavailableFeature="features.LowHighIndicator; else lowHighIndicatorPlaceholder"
  ></app-wheel-chart-legend>

  <ng-template #lowHighIndicatorPlaceholder>
    <div class="low-high-indicator-placeholder">
      <button
        (click)="$event.preventDefault(); redirectToLowHighIndicatorDemoPage()"
        mat-button
        class="mat-button-link link-button locked-for-current-edition"
      >
        Lowest / Highest Close
      </button>
    </div>
  </ng-template>
</app-search-bar>

<app-doubled-chart
  [defaultChartType]="chartTypes.Day1"
  [afterSavedDataRestored]="afterSavedDataRestored.bind(this)"
  [chartSavedDataKey]="chartSavedDataKey"
  [chartSavedDataKeyPrefix]="'wheelStrategy'"
  [isDeveloperAccount]="isDeveloperAccount"
  [getChartShapeIdsToExclude]="getChartShapeIdsToExclude"
  [getCustomIndicators]="getCustomIndicators"
  [getTimescaleMarks]="getTimescaleMarks"
  [getExpectedMoveOptions]="getExpectedMoveOptions"
  [loadChartData]="loadChartData"
  [symbol$]="currentSymbol$"
  [resetChart$]="resetChart$"
  [disabledIndicators]="disabledIndicators"
  [widgetOptionsOverrides]="widgetOptionsOverrides"
  (chartReady)="onChartReady($event)"
  (symbolChanged)="onSymbolChanged($event)"
  class="chart-dividends-strategy-container"
></app-doubled-chart>

<div
  *ngIf="false"
  class="chart-overlay"
></div>
